<template>
    <div>
        <v-row justify="center" class="row--small">
            <v-col v-for="item in items" :key="item.title" cols="auto">
                <p class="grey--text text--lighten-1">{{ item.title }} {{ item.info }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                title: "주소",
                info: "충청남도 금산군 금산읍 금산천길 106",
            },
            {
                title: "이메일",
                info: "gsshplus@naver.com",
            },
            // {
            //     title: "팩스",
            //     info: "",
            // },
            {
                title: "대표번호",
                info: "041-750-2603 (금산군 농촌활력팀)",
            },
            {
                title: "사업자등록번호",
                info: "617-82-84288",
            },
        ],
    }),
};
</script>