import { render, staticRenderFns } from "./v-gender-radio-group.vue?vue&type=template&id=7b3e5de6"
import script from "./v-gender-radio-group.vue?vue&type=script&lang=js"
export * from "./v-gender-radio-group.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports