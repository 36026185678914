<template>
    <client-page>
        <sub-visual sh="아카이빙" tabActive="프로젝트" text="금산에 새로운 활력이 넘치는 소중한 순간을 기록하였습니다." image="/images/archiving/visual.jpg" />

        <page-section title="주요사업" subtitle="Project" class="page-section--first">
            <div class="tit text-center font-weight-bold pb-18 pb-md-24">추진성과</div>
            <div class="px-xl-124">
                <v-row>
                    <template v-for="(item, index) in result">
                        <v-col :key="index" cols="6" md="3" class="result-list-item">
                            <v-card flat outlined rounded="circle">
                                <v-responsive :aspect-ratio="1 / 1" class="d-flex text-center align-center">
                                    <div class="tit tit--xxs line-height-1 grey--text text--darken-4 mb-6 mb-md-8">
                                        {{ item.name }}
                                    </div>
                                    <div class="tit tit--lg font-weight-bold line-height-1">
                                        <span class="font-weight-regular">+</span>
                                        <ICountUp startVal="0" :endVal="item.number" duration="2.5" class="iCountUp" :options="options" @ready="onReady" />
                                    </div>
                                </v-responsive>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>
            </div>
        </page-section>

        <page-section class="page-section--last">
            <div class="board-container">
                <board-list-search :code="ARCHIVING_BOARD_TYPES.PROJECT.value" />
                <v-divider class="v-divider--dark my-18 my-md-24" />
                <v-row>
                    <v-col v-for="(board, index) in boards" :key="index" cols="6" md="4">
                        <v-card tile flat :to="`./project/${board?._id}`">
                            <v-card :img="board?.thumb?.src" flat class="rounded-sm">
                                <v-responsive :aspect-ratio="385 / 376" />
                            </v-card>

                            <v-card-title class="pt-24 px-0">
                                <div class="text-truncate-2 font-weight-medium grey--text text--darken-4">{{ board?.subject }}</div>
                            </v-card-title>
                            <v-sheet color="grey lighten-5" class="responsive-radius px-12 py-8">
                                <v-row class="row--xxs">
                                    <v-col cols="auto">
                                        <span class="tertiary--text font-weight-bold">사업기간</span>
                                    </v-col>
                                    <v-col>
                                        <div class="grey--text">{{ $dayjs(board?.businessStartsAt).format("YYYY.MM.DD") }} ~ {{ $dayjs(board?.businessEndsAt).format("YYYY.MM.DD") }}</div>
                                    </v-col>
                                </v-row>
                            </v-sheet>
                            <v-card-text class="px-12 pt-8 pb-16">
                                <div class="text-truncate-2">{{ board?.summary }}</div>
                            </v-card-text>
                            <v-divider />
                        </v-card>
                    </v-col>
                </v-row>
                <div class="v-pagination-wrap">
                    <v-pagination v-model="page" :length="pageCount" :total-visible="20" @input="(page) => $router.push({ query: { page } })" />
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import ICountUp from "vue-countup-v2";
import { ARCHIVING_BOARD_TYPES } from "@/assets/variables";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import BoardListSearch from "@/components/client/archiving/board-list-search.vue";

export default {
    components: {
        ICountUp,
        SubVisual,
        ClientPage,
        PageSection,
        BoardListSearch,
    },
    data: () => ({
        result: [
            {
                name: "누적 프로젝트",
                number: 253,
            },
            {
                name: "활동 팀",
                number: 21,
            },
            {
                name: "개인 활동가",
                number: 36,
            },
            {
                name: "혜택 주민",
                number: 140,
            },
        ],
        options: {
            useEasing: true,
            useGrouping: true,
            separator: ",",
            enableScrollSpy: true,
        },
        ARCHIVING_BOARD_TYPES,
        boards: [],
        summary: { totalCount: 0 },

        page: 1,
        limit: 9,
    }),
    computed: {
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        query() {
            let { ...query } = this.$route.query;

            query.code = ARCHIVING_BOARD_TYPES.PROJECT.value;
            return query;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search();
        },
        async search() {
            try {
                let { summary, boards } = await api.v1.archiving.board.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.query,
                });

                this.boards = boards;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        onReady: function (instance, CountUp) {
            const that = this;
        },
    },
    watch: {
        query() {
            this.search();
        },
    },
};
</script>

<style lang="scss" scoped>
.result-list-item {
    &:nth-child(1) {
        > .v-card {
            color: var(--v-primary-base);
        }
    }
    &:nth-child(2) {
        > .v-card {
            color: var(--v-secondary-base);
        }
    }
    &:nth-child(3) {
        > .v-card {
            color: var(--v-tertiary-base);
        }
    }
    &:nth-child(4) {
        > .v-card {
            color: #37b0b8;
        }
    }
    > .v-card {
        border-width: 2px;
        border-color: currentColor;
    }
}
.responsive-radius {
    border-radius: 16px !important;
}
@media (min-width: 768px) {
    .responsive-radius {
        border-radius: 999px !important;
    }
}
</style>
