<template>
    <client-page>
        <sub-visual sh="금산소식" tabActive="자유게시판" text="금산군 농촌신활력플러스사업과 관련된 다양한 정보를 알리고 여러분과 자유로운 소통을 위한 곳입니다." image="/images/news/visual.jpg" />

        <page-section class="page-section--first page-section--last">
            <div role="table" class="board-table board-table--view">
                <div role="rowgroup">
                    <div role="row">
                        <div role="columnheader" class="py-16">
                            <v-row align="center">
                                <v-col cols="1" class="text-center">카테고리</v-col>
                                <v-col cols="3"><v-select v-model="form._category" :items="categoryItems" hide-details></v-select></v-col>
                                <v-col cols="1" class="text-center">제목</v-col>
                                <v-col><v-text-field v-model="form.subject" hide-details></v-text-field></v-col>
                            </v-row>
                        </div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <div class="board-content">
                                <naver-smarteditor v-model="form.content" id="content" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-24">
                <v-row class="row--xxs">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="h-40px" @click="$router.go(-1)">
                            <span class="grey--text font-size-14">리스트</span>
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn outlined color="tertiary" class="h-40px" @click="save">
                            <span class="font-size-14">작성하기</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { BOARD_TYPES, initBoard } from "@/assets/variables";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        NaverSmarteditor,
    },
    data: () => ({
        categoryItems: [],
        form: initBoard({ code: BOARD_TYPES.COMMUNITY.value }),
    }),
    computed: {
        _board() {
            return this.$route?.params?._board;
        },
        isCreate() {
            return !this._board;
        },
    },
    async mounted() {
        await this.searchCategories();
        if(!this.isCreate) await this.search();
    },
    methods: {
        async searchCategories() {
            try {
                let { categories } = await api.v1.boards.categories.gets();

                this.categoryItems = categories.map(({ _id, title }) => ({ text: title, value: _id }));
            } catch (error) {
                this.$handleError(error);
            }
        },
        async search() {
            try {
                let { board } = await api.v1.boards.get({ _id: this._board });

                this.form = initBoard(board);
            } catch (error) {
                this.$handleError(error);
            }
        },
        async save() {
            try {
                if (!this.validate()) return;

                let { put, post } = api.v1.boards;

                await (this.isCreate ? post : put)(this.form);

                alert("작성이 완료되었습니다.");
                this.$router.go(-1);
            } catch (error) {
                this.$handleError(error);
            }
        },
        validate() {
            if (!this.form._category) throw new Error("카테고리를 선택해주세요.");
            if (!this.form.subject) throw new Error("제목을 작성해주세요");
            if (!this.form.content) throw new Error("내용을 작성해주세요");

            return true;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
}
</style>
