var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "금산소식",
      "tabActive": "자유게시판",
      "text": "금산군 농촌신활력플러스사업과 관련된 다양한 정보를 알리고 여러분과 자유로운 소통을 위한 곳입니다.",
      "image": "/images/news/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "py-16",
    attrs: {
      "role": "columnheader"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "1"
    }
  }, [_vm._v("카테고리")]), _c('v-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.categoryItems,
      "hide-details": ""
    },
    model: {
      value: _vm.form._category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_category", $$v);
      },
      expression: "form._category"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "1"
    }
  }, [_vm._v("제목")]), _c('v-col', [_c('v-text-field', {
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.form.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form, "subject", $$v);
      },
      expression: "form.subject"
    }
  })], 1)], 1)], 1)]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [_c('naver-smarteditor', {
    attrs: {
      "id": "content"
    },
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)])])])]), _c('div', {
    staticClass: "mt-24"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text font-size-14"
  }, [_vm._v("리스트")])])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "outlined": "",
      "color": "tertiary"
    },
    on: {
      "click": _vm.save
    }
  }, [_c('span', {
    staticClass: "font-size-14"
  }, [_vm._v("작성하기")])])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }