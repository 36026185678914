import { render, staticRenderFns } from "./terms-of-agreements.vue?vue&type=template&id=74c4e6d6&scoped=true"
import script from "./terms-of-agreements.vue?vue&type=script&lang=js"
export * from "./terms-of-agreements.vue?vue&type=script&lang=js"
import style0 from "./terms-of-agreements.vue?vue&type=style&index=0&id=74c4e6d6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c4e6d6",
  null
  
)

export default component.exports