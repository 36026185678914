var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "금산소식",
      "tabActive": "보도자료",
      "text": "금산군 농촌신활력플러스사업과 관련된 다양한 정보를 알리고 여러분과 자유로운 소통을 위한 곳입니다.",
      "image": "/images/news/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "board-container"
  }, [_c('board-list-search', {
    attrs: {
      "code": _vm.BOARD_TYPES.NEWS.value
    }
  }), _c('table', {
    staticClass: "board-list mt-12 mt-md-20"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "calc(1000 / 1200 * 100%)"
    }
  }, [_vm._v("제목")]), _c('th', {
    staticStyle: {
      "width": "calc(200 / 1200 * 100%)"
    }
  }, [_vm._v("작성일")])])]), _c('tbody', [_vm._l(_vm.fixed, function (board, index) {
    return _c('tr', {
      key: board._id,
      staticClass: "board-list__info-row"
    }, [_c('td', {
      key: index,
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${board._id}`);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-alert-circle-outline")]), _c('span', {
      staticClass: "ellip"
    }, [_vm._v(_vm._s(board.subject))])], 1), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.createdAt.toDate()) + " ")])]);
  }), _vm._l(_vm.boards, function (board, index) {
    return _c('tr', {
      key: board._id
    }, [_c('td', {
      key: index,
      staticClass: "board-list__tit",
      attrs: {
        "align": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(`${_vm.$route.path}/${board._id}`);
        }
      }
    }, [_c('div', {
      staticClass: "ellip"
    }, [_vm._v(_vm._s(board.subject))])]), _c('td', {
      staticClass: "board-list__txt",
      attrs: {
        "align": "center"
      }
    }, [_vm._v(" " + _vm._s(board.createdAt.toDate()) + " ")])]);
  })], 2)]), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }