var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "소개",
      "tabActive": "오시는 길",
      "text": "금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다.",
      "image": "/images/about/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first"
  }, [_c('iframe', {
    staticClass: "map",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3223.751587176697!2d127.48928847638153!3d36.09954310688103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356558499f5d55f5%3A0xbcec78e1c2e9d24!2z7Lap7LKt64Ko64-EIOq4iOyCsOq1sCDquIjsgrDsnY0g6riI7IKw7LKc6ri4IDEwNg!5e0!3m2!1sko!2skr!4v1737017435896!5m2!1sko!2skr",
      "width": "100%",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), _c('page-section', {
    staticClass: "pt-0 page-section--last"
  }, [_c('v-divider', {
    staticClass: "v-divider--dark"
  }), _c('div', {
    staticClass: "py-30 py-md-40"
  }, [_c('v-row', {
    staticClass: "page-text page-text--lg",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-sheet', {
    staticClass: "d-flex align-center line-height-15 pb-12",
    attrs: {
      "width": "140"
    }
  }, [_c('v-icon', {
    staticClass: "mr-8 mr-md-12"
  }, [_vm._v("mdi-map-marker-outline")]), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v("주소")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": ""
    }
  }, [_c('p', {
    staticClass: "grey--text line-height-15"
  }, [_vm._v("충청남도 금산군 금산읍 금산천길 106")])])], 1)], 1), _c('v-divider')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }