var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "visual"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "swiper-slide"
    }, [_c('img', {
      staticClass: "visual__image",
      attrs: {
        "src": item.image,
        "alt": ""
      }
    }), _c('v-container', {
      staticClass: "container--lg"
    }, [_c('v-row', {
      staticClass: "row--lg",
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "auto"
      }
    }, [_c('div', {
      staticClass: "d-lg-none"
    }, [_c('div', {
      staticClass: "pointed-title tit white--text font-weight-medium"
    }, [_vm._v(" 금산에 불어오는 새로운 활력"), _c('br'), _vm._v(" 이 곳은 "), _c('strong', {
      staticClass: "strong"
    }, [_vm._v("금산살이")]), _vm._v("입니다. ")])]), _c('div', {
      staticClass: "d-none d-lg-block"
    }, [_c('div', {
      staticClass: "pointed-title tit tit--xl white--text font-weight-medium"
    }, [_vm._v(" 금산에 불어오는 새로운 활력"), _c('br'), _vm._v(" 이 곳은 "), _c('strong', {
      staticClass: "strong"
    }, [_vm._v("금산살이")]), _vm._v("입니다. ")])])]), _c('v-col', {
      attrs: {
        "cols": "8",
        "lg": ""
      }
    }, [_c('v-divider', {
      staticClass: "visaul__divider"
    })], 1), _c('v-col', {
      attrs: {
        "cols": "4",
        "lg": "auto"
      }
    }, [_c('router-link', {
      staticClass: "tit tit--sm white--text d-flex align-center",
      attrs: {
        "to": "/about/greetings"
      }
    }, [_vm._v(" 소개 더보기 "), _c('icon-link-arrow', {
      attrs: {
        "white": ""
      }
    })], 1)], 1)], 1), _c('div', {
      staticClass: "swiper-pagination"
    })], 1)], 1);
  }), 0)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }