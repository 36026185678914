<template>
    <client-page>
        <sub-visual sh="소개" tabActive="사업추진단" text="금산군에서 나의 자리를 찾다. 금산군 농촌신활력플러스사업을 소개합니다." image="/images/about/visual.jpg" />

        <page-section class="page-section--first page-section--last">
            <v-sheet color="grey lighten-5" class="grey-background">
                <v-img max-width="606" src="/images/about/team/team.png" class="mr-auto ml-auto mr-xl-0" />
            </v-sheet>

            <div class="mt-40 mt-md-60">
                <v-row>
                    <v-col cols="12" md="5">
                        <div class="font-size-24 font-size-md-28 font-weight-medium mb-18 mb-md-24">금산군 농촌신활력 플러스사업 추진단</div>

                        <div class="page-text page-text--lg">
                            <v-row align="center" class="row--sm grey--text">
                                <template v-for="(item, index) in informations">
                                    <v-col :key="index" cols="12">
                                        <v-row class="row--sm">
                                            <v-col cols="auto">
                                                {{ item.title }}
                                            </v-col>
                                            <v-col>
                                                {{ item.text }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </template>
                            </v-row>

                            <div class="sns-list mt-18 mt-md-24">
                                <v-row class="row--sm">
                                    <template v-for="(item, index) in sns">
                                        <v-col :key="index" cols="12">
                                            <a :href="item.link" target="_blank" class="d-inline-block">
                                                <v-row align="center" class="row--xxs">
                                                    <v-col cols="auto">
                                                        <v-img max-width="20" :src="item.icon" />
                                                    </v-col>
                                                    <v-col cols="auto">
                                                        {{ item.title }}
                                                    </v-col>
                                                </v-row>
                                            </a>
                                        </v-col>
                                    </template>
                                </v-row>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-simple-table v-if="organization?.length" class="v-data-table--no-hover">
                            <template #default>
                                <thead>
                                    <tr>
                                        <th>직급</th>
                                        <th>주요업무</th>
                                        <th>전화번호</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in organization">
                                        <tr :key="index">
                                            <td>
                                                {{ item?.name }}
                                            </td>
                                            <td class="text-start">
                                                {{ item?.desc }}
                                            </td>
                                            <td>
                                                {{ item?.phone }}
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data: () => ({
        informations: [
            {
                title: "대표번호",
                text: "041-750-2603 (금산군 농촌활력팀)",
            },
            {
                title: "대표이메일",
                text: "gsshplus@naver.com",
            },
        ],
        sns: [
            {
                title: "Instagram",
                icon: "/images/icon/icon-instagram.svg",
                link: "https://www.instagram.com/gsshplus/",
            },
            {
                title: "Blog",
                icon: "/images/icon/icon-blog.svg",
                link: "https://blog.naver.com/gsshplus",
            },
            {
                title: "Youtube",
                icon: "/images/icon/icon-youtube.svg",
                link: "https://www.youtube.com/@geumsanplus",
            },
            // {
            //     title: "Cafe",
            //     icon: "/images/icon/icon-cafe.svg",
            //     link: "/",
            // },
        ],
        organization: [],
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.organization = this.$store.state.setting?.organization;
        },
    },
};
</script>

<style lang="scss" scoped>
.grey-background {
    border-radius: 20px;
    padding: 20px;
}
.sns-list {
    border-left: 2px solid #000;
    padding-left: 16px;
}
.v-data-table {
    ::v-deep {
        > .v-data-table__wrapper {
            > table {
                > thead {
                    > tr {
                        > th {
                            border-bottom: thin solid var(--v-grey-darken4) !important;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    .grey-background {
        padding: 40px;
    }
}
@media (min-width: 1200px) {
    .grey-background {
        border-radius: 999px;
        padding: 60px 204px;
    }
}
</style>
