<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <list-heading :title="title">
                <template #buttons v-if="code == ARCHIVING_BOARD_TYPES.GALLERY.value">
                    <board-gallery-video-form>
                        <template #activator="{ attrs, on }">
                            <v-btn tile color="primary" title="공지사항 생성" v-bind="attrs" v-on="on"># 소개 영상 링크 설정 </v-btn>
                        </template>
                    </board-gallery-video-form>
                </template>
            </list-heading>
            <board-list-search v-bind="{ isProject }" flat outlined />

            <v-data-table v-bind="{ headers: itemHeaders, items, loading }" disable-sort disable-pagination hide-default-footer :items-per-page="-1">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.index`]="{ index }">
                    {{ summary.totalCount - skip - index }}
                </template>

                <template #[`item.thumb`]="{ item }">
                    <image-popup v-bind="{ src: item?.thumb?.src, size: '30', rounded: true }" class="cursor-pointer" />
                </template>

                <template #[`item.businessHour`]="{ item }"> {{ $dayjs(item?.businessStartsAt).format("YYYY-MM-DD") }} ~ {{ $dayjs(item?.businessEndsAt).format("YYYY-MM-DD") }} </template>

                <template #[`item.actions`]="{ item }">
                    <board-content-preview v-bind="{ content: item?.content }">
                        <template #activator="{ attrs, on }">
                            <v-btn small text icon v-bind="attrs" v-on="on">
                                <v-icon small> mdi-file-find </v-icon>
                            </v-btn>
                        </template>
                    </board-content-preview>

                    <v-btn small text icon @click="edit(item)">
                        <v-icon small> mdi-pencil </v-icon>
                    </v-btn>

                    <v-btn small text icon @click="remove(item)">
                        <v-icon small> mdi-delete </v-icon>
                    </v-btn>
                </template>
                <template #footer>
                    <v-divider />
                    <v-pagination :value="page" :length="pageCount" :total-visible="20" class="my-2" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination> </template
            ></v-data-table>

            <v-btn bottom color="accent" fab fixed right @click="create()"> <v-icon>mdi-pencil</v-icon> </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from "@/plugins/vue-dayjs";
import { ARCHIVING_BOARD_TYPES, initDataTableHeaders } from "@/assets/variables";
import ImagePopup from "@/components/console/dumb/image-popup.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import ListHeading from "@/components/console/dumb/list-heading.vue";
import BoardListSearch from "@/components/console/archiving/boards/board-list-search.vue";
import BoardContentPreview from "@/components/console/archiving/boards/board-content-preview.vue";
import BoardGalleryVideoForm from "@/components/console/archiving/boards/board-gallery-video-form.vue";

const headers = initDataTableHeaders([
    { text: "#", value: "index", width: 20 },
    { text: "썸네일", value: "thumb", width: 30 },
    { text: "제목", value: "subject", width: +150 },
    { text: "요약", value: "summary", width: +150, isProject: true },
    // { text: "내용", value: "content", width: 10 },
    { text: "조회수", value: "viewCount", width: 30 },
    { text: "사업기간", value: "businessHour", width: 100, isProject: true },
    { text: "작성일자", value: "createdAt", width: 100, formatter: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss") },
    { text: "Actions", value: "actions", width: 20 },
]);

export default {
    components: {
        ImagePopup,
        VDateField,
        ListHeading,
        BoardListSearch,
        BoardContentPreview,
        BoardGalleryVideoForm,
    },
    props: {
        code: { type: String, default: null },
    },
    data() {
        return {
            headers,
            loading: false,

            limit: 10,

            boards: [],
            summary: { totalCount: 0 },

            ARCHIVING_BOARD_TYPES,
        };
    },
    computed: {
        items() {
            return [...this.boards];
        },
        itemHeaders() {
            return this.isProject ? headers : headers.filter(({ isProject }) => !isProject);
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        query() {
            let { ...query } = this.$route.query;
            query.code = this.code;

            return query;
        },
        isProject() {
            return this.code === ARCHIVING_BOARD_TYPES.PROJECT.value;
        },
        title() {
            return `아카이빙 - ${this.isProject ? "프로젝트" : "갤러리"}`;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.search();
        },
        async search() {
            try {
                let { summary, boards } = await api.console.archiving.board.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.query,
                });

                this.boards = boards;
                this.summary = summary;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`${this.$route.path}/create`);
        },
        edit(board) {
            this.$router.push(`${this.$route.path}/${board._id}`);
        },
        async remove(board) {
            if (this.loading) return;
            this.loading = true;
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    if (board._thumb) await api.console.common.files.delete({ _id: board._thumb });
                    await api.console.archiving.board.delete(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        "$route.query"() {
            this.search();
        },
    },
};
</script>
