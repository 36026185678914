<template>
    <client-page>
        <sub-visual sh="금산소식" tabActive="보도자료" text="금산군 농촌신활력플러스사업과 관련된 다양한 정보를 알리고 여러분과 자유로운 소통을 위한 곳입니다." image="/images/news/visual.jpg" />

        <page-section class="page-section--first page-section--last">
            <div role="table" class="board-table board-table--view">
                <div role="rowgroup">
                    <div role="row">
                        <div role="columnheader">{{ board?.subject }}</div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <ul class="board-view-data">
                                <li>
                                    작성일 :
                                    {{ board?.createdAt.toDate() }}
                                </li>
                                <li>
                                    조회수 :
                                    {{ board?.viewCount }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div role="row">
                        <div role="cell">
                            <div class="board-content">
                                <iframe v-if="board?.youtubeCode" width="100%" :src="`https://www.youtube.com/embed/${board?.youtubeCode}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen class="youtube rounded-sm mb-18 mb-md-24" />
                                <div v-html="board.content.replace(/\n/g, '<br>')"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-24">
                <v-row class="row--xxs">
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="h-40px" @click="$router.go(-1)">
                            <span class="grey--text font-size-14">리스트</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { initBoard } from "@/assets/variables";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
    },
    data() {
        return {
            board: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { board } = await api.v1.boards.get({ _id: this.$route.params._news });
                this.board = initBoard(board);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.youtube {
    aspect-ratio: 16 / 9;
}
@media (min-width: 768px) {
}
</style>
