var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--sub"
  }, [_c('sub-visual', {
    attrs: {
      "sh": "개인정보처리방침"
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('terms-component', {
    attrs: {
      "code": "privacy-policy"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }