<template>
    <client-page>
        <sub-visual sh="금산소식" tabActive="공지사항" text="금산군 농촌신활력플러스사업과 관련된 다양한 정보를 알리고 여러분과 자유로운 소통을 위한 곳입니다." image="/images/news/visual.jpg" />

        <page-section title="" subtitle="Gallery" class="page-section--first page-section--last">
            <notification-view />
        </page-section>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import NotificationView from '@/components/client/center/notifications/notification-view.vue';

export default {
    components: {
        SubVisual,
        ClientPage,
        PageSection,
        NotificationView,
    },
    data: () => ({
    }),
};
</script>

<style lang="scss" scoped>
iframe {
    aspect-ratio: 16/ 9;
}

@media (min-width: 768px) {
}
</style>
