var render = function render(){
  var _vm$board, _vm$board2, _vm$board3, _vm$board4, _vm$board5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "sh": "금산소식",
      "tabActive": "보도자료",
      "text": "금산군 농촌신활력플러스사업과 관련된 다양한 정보를 알리고 여러분과 자유로운 소통을 위한 곳입니다.",
      "image": "/images/news/visual.jpg"
    }
  }), _c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('div', {
    staticClass: "board-table board-table--view",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v(_vm._s((_vm$board = _vm.board) === null || _vm$board === void 0 ? void 0 : _vm$board.subject))])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('ul', {
    staticClass: "board-view-data"
  }, [_c('li', [_vm._v(" 작성일 : " + _vm._s((_vm$board2 = _vm.board) === null || _vm$board2 === void 0 ? void 0 : _vm$board2.createdAt.toDate()) + " ")]), _c('li', [_vm._v(" 조회수 : " + _vm._s((_vm$board3 = _vm.board) === null || _vm$board3 === void 0 ? void 0 : _vm$board3.viewCount) + " ")])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "board-content"
  }, [(_vm$board4 = _vm.board) !== null && _vm$board4 !== void 0 && _vm$board4.youtubeCode ? _c('iframe', {
    staticClass: "youtube rounded-sm mb-18 mb-md-24",
    attrs: {
      "width": "100%",
      "src": `https://www.youtube.com/embed/${(_vm$board5 = _vm.board) === null || _vm$board5 === void 0 ? void 0 : _vm$board5.youtubeCode}`,
      "title": "YouTube video player",
      "frameborder": "0",
      "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      "referrerpolicy": "strict-origin-when-cross-origin",
      "allowfullscreen": ""
    }
  }) : _vm._e(), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.board.content.replace(/\n/g, '<br>'))
    }
  })])])])])]), _c('div', {
    staticClass: "mt-24"
  }, [_c('v-row', {
    staticClass: "row--xxs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-40px",
    attrs: {
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "grey--text font-size-14"
  }, [_vm._v("리스트")])])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }